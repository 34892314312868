/*
 * @Date: 2023-02-07 11:33:51
 * @LastEditors: simLarry
 * @LastEditTime: 2023-05-30 21:59:58
 * @FilePath: \F1-M3-QRP-CODE\pages\m3-005.js
 */

import React from 'react';
import { fetchRequest } from '$CONFIG/Fatch.config';

import Header from '@/hayhar/header'; // 头部 组件
import Central from '@/hayhar/central'; // 表单 组件
import Footer from '@/hayhar/footer'; // 底部内容
import Socialbtn from '@/hayhar/socialbtn'; // 社交按钮
import Modal from '@/modal'; //
import { prependCMSURL4CMSImageValue } from '../components/helper';
import { ApiPort } from '$ACTIONS/API';
import HostConfig from '$CONFIG/Host.config'
import Router from 'next/router';
import { checkAffQueryString, setupFooterLinks, getApiVersion } from '../actions/util';
export const CMSURL = HostConfig.Config.CMSURL;

import setupTraces from "../config/trace";
setupTraces('m3-005');

import { bannerConfigs } from '../config/default/banner';
export default class hayHar extends React.Component {
	constructor() {
        super();
        this.state = {
            bannerList: bannerConfigs['m3-005'],
            pagename: '',

            mainSiteUrl : '',
            liveChatUrl : '',

            isModal:false,
            error_text : '',
        }

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }


	componentDidMount() {
        this.init();
    }

	init() {
        // 获取URL 参数
        checkAffQueryString(global.location.search);
        // 获取banner
        this.getBanner();
        // 获取当前page
        this.getPageName();

        this.QrpLoad();
    }

	/**
     * 获取当前路径
     */
	getPageName() {
        this.setState({
            pagename: 'm3-005',
        })
    }


	getBanner(){
        let bannerList ;
        fetchRequest(ApiPort.Banner5 , 'GET').then(res=>{
			try {
				if(res){
					if(res.length > 0){
						this.state.bannerList = res.map((val , index)=>{
                            return { cmsImageUrlWeb: prependCMSURL4CMSImageValue(CMSURL, val.cmsImageUrlWeb), cmsImageUrlMobile: prependCMSURL4CMSImageValue(CMSURL, val.cmsImageUrlMobile) , cmsImageUrlMobileAlt : val.cmsImageUrlMobileAlt , cmsImageUrlWebAlt : val.cmsImageUrlWebAlt}
                        })

					}
				}
			} catch (error) {

			}
		})
    }


	QrpLoad() {
        fetchRequest(ApiPort.QrpLoad, 'GET', '', getApiVersion()).then(res => {
            if (res) {
                if (res.isSuccess){
                    this.setState({
                        liveChatUrl : res.liveChatUrl,
                        mainSiteUrl : res.mainSiteUrl,
                        nativeAppDownloadUrl : res.nativeAppDownloadUrl
                    }, ()=>{
                        setupFooterLinks(res)
                    })
                }
            }
        })
    }


	closeModal(){
        this.setState({
            isModal : false
        })
    }

	openModal(text){
        this.setState({
            error_text : text,
            isModal : !this.state.isModal
        })
    }

	render(){
		let { bannerList, pagename , mainSiteUrl , liveChatUrl} = this.state;


		return (
			<div className={`hayhar-page ${pagename}`}>
                {/* 顶部 */}
                <Header openModal={this.openModal} mainSiteUrl={mainSiteUrl}></Header>
                {/* 表单 */}
                <Central openModal={this.openModal} bannerList={bannerList} mainSiteUrl={mainSiteUrl} Copies={true}></Central>
                {/* 底部 */}
                <Footer mainSiteUrl={mainSiteUrl} is2023Ver2={true}></Footer>
                {/* 社交按钮 */}
                <Socialbtn liveChatUrl={liveChatUrl}></Socialbtn>

                {/* 弹窗提示 */}
                <Modal error_text={this.state.error_text} closeModal={this.closeModal} isModal={this.state.isModal}></Modal>
            </div>
		)
	}
}
